<template>
  <div>
    <fenleilist ref="fenleilist"></fenleilist>
    <bottombox></bottombox>
    
  </div>
</template>

<script>
import fenleilist from "./components/scfenleilist.vue";
import bottombox from "./components/bottombox.vue";

export default {
  name: "home",
  components: {
    fenleilist,
    bottombox
  },
  data() {
    return {
      Inv:1
    };
  },
  created() {
    
  },
  mounted() {
      this.$refs.fenleilist.init(this.$route.query.collectStatus)
  },

  methods: {
    
  },
};
</script>


<style lang="scss" scoped>
</style>